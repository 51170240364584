.cursor-pointer {
    cursor: pointer;
}

.color-success {
    color: #73a839;
}

.color-danger {
    color: #c71c22;
}

.text-center {
    text-align: center;
}

.header-name {
    font-size: 16px!important;
}