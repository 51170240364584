.app-page-title {
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 0.5rem !important;
}

.page-filter-input {
  margin-top: 9px;
}


.page-filter-button {
  margin-top: 9px;
}

.custom-invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #d92550;
}

.border-radius-0 {
  border-radius: 0px!important;
}